<template>
  <div class="table-responsive">
    <table
      class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-50px"></th>
          <th class="min-w-lg-500px">Name</th>
          <th class="min-w-120px text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="parentEquipmentCategory in tableData"
          :key="parentEquipmentCategory.id"
        >
          <td class="text-start">
            <a
              v-if="parentEquipmentCategory.children.length > 0"
              type="button"
              class="btn btn-sm btn-info"
              @click="
                childrenEquipmentCategoriesButtonClick(
                  parentEquipmentCategory
                )
              "
              data-bs-toggle="modal"
              data-bs-target="#childrenEquipmentCategoriesModal"
              >View sub categories</a
            >
          </td>
          <td>
            <router-link
              :to="'/equipment-category/' + parentEquipmentCategory.id"
              class="text-dark fw-bolder text-hover-primary fs-6"
              >{{ parentEquipmentCategory.name }}</router-link
            >
          </td>
          <td class="text-end">
            <div class="btn-group btn-group-sm pb-2 pt-1" role="group">
              <button
                  @click="emitEditEvent(parentEquipmentCategory)"
                  type="button"
                  class="btn btn-sm btn-warning"
                  data-bs-toggle="modal"
                  data-bs-target="#EquipmentCategoryModal"
              >
                Edit
              </button>
              <button
                  @click="openDeleteModal(parentEquipmentCategory)"
                  class="btn btn-sm btn-danger"
                  data-bs-target="#deleteModal"
                  data-bs-toggle="modal"
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "EquipmentCategoriesDataTable",
  props: {
    tableData: Object
  },
  data() {
    return {
      openEquipmentCategories: []
    };
  },
  methods: {
    childrenEquipmentCategoriesButtonClick(equipmentCategory) {
      this.$emit("childrenEquipmentCategories", equipmentCategory);
    },
    emitEditEvent(equipmentCategory) {
      this.$emit("editModalOpened", equipmentCategory);
    },
    openDeleteModal(equipmentCategory) {
      this.$emit("deleteModalOpened", equipmentCategory);
    }
  }
});
</script>
