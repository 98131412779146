
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import EquipmentCategoryModal from "@/components/settings/modals/EquipmentCategoryModal.vue";
import ChildrenEquipmentCategoriesModal from "@/components/general/ChildrenEquipmentCategoriesModal.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";
import EquipmentCategoriesDataTable from "@/components/settings/Tables/EquipmentcategoriesDataTable.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import Pagination from "@/components/pagination/Pagination.vue";

export default defineComponent({
  name: "EquipmentCategoriesIndex",
  components: {
    EquipmentCategoryModal,
    ChildrenEquipmentCategoriesModal,
    EquipmentCategoriesDataTable,
    DeleteModal,
    LoadingSpinner,
    Pagination
  },
  props: {
    widgetClasses: String
  },
  data() {
    return {
      currentlySelectedParentEquipmentCategory: {
        id: null,
        name: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        parent_id: null,
        children: []
      },
      isThereAModalActive: false,
      modalSetup: {
        action: "",
        title: "",
        parentEquipmentCategory: ""
      },
      pagination: {}
    };
  },
  methods: {
    ...mapActions("EquipmentCategoriesModule", [
      "fetchData",
      "createNew",
      "update",
      "destroyData",
      "setPageNumber",
      "resetPageNumber",
      "resetState"
    ]),
    changeSearchByPage(pageNumber) {
      this.setPageNumber(pageNumber);
      this.fetchData();
    },
    openChildrenEquipmentCategories(parentEquipmentCategory) {
      this.resetCurrentlySelectedParentEquipmentCategory();
      this.currentlySelectedParentEquipmentCategory.id =
        parentEquipmentCategory.id;
      this.currentlySelectedParentEquipmentCategory.name =
        parentEquipmentCategory.name;
      this.currentlySelectedParentEquipmentCategory.children =
        parentEquipmentCategory.children;
    },
    openAddNewModal(parentEquipmentCategory) {
      this.resetCurrentlySelectedParentEquipmentCategory();
      if (parentEquipmentCategory) {
        this.modalSetup.title = `Add new Sub Category for ${parentEquipmentCategory.name}`;
        this.modalSetup.parentEquipmentCategory = parentEquipmentCategory;
      } else {
        this.modalSetup.title = "Add new Equipment Category";
        this.modalSetup.parentEquipmentCategory = "";
      }
      this.modalSetup.action = "addnew";
      this.isThereAModalActive = true;
    },
    saveDataFromModal(dataFromModal) {
      if (dataFromModal.action == "edit") {
        this.update({
          values: dataFromModal.payload,
          id: this.currentlySelectedParentEquipmentCategory.id
        });
      } else {
        this.createNew(dataFromModal.payload);
      }
    },
    openEditModal(equipmentCategory) {
      this.modalSetup.parentEquipmentCategory = "";
      if (equipmentCategory.parent_id) {
        this.modalSetup.title = "Edit Sub Category";
      } else {
        this.modalSetup.title = "Edit Equipment Category";
      }
      this.modalSetup.action = "edit";
      this.currentlySelectedParentEquipmentCategory = equipmentCategory;
    },
    openDeleteModal(entry) {
      this.currentlySelectedParentEquipmentCategory = entry;
      this.isThereAModalActive = true;
    },
    deleteSelectedEquipmentCategory() {
      this.destroyData(this.currentlySelectedParentEquipmentCategory.id);
      this.isThereAModalActive = false;
      this.resetCurrentlySelectedParentEquipmentCategory();
    },
    resetCurrentlySelectedParentEquipmentCategory() {
      this.currentlySelectedParentEquipmentCategory = {
        id: null,
        name: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        parent_id: null,
        children: []
      };
    }
  },
  computed: {
    ...mapGetters("EquipmentCategoriesModule", [
      "equipmentCategoriesList",
      "loading",
      "getPaginationData"
    ])
  },
  created() {
    this.fetchData();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  }
});
