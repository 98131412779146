<template>
  <!--begin::Modal-->
  <div
    class="modal fade"
    id="childrenEquipmentCategoriesModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
          <!--begin::Heading-->
          <div class="text-center mb-13">
            <!--begin::Title-->
            <h1 class="mb-3">View sub categories</h1>
            <!--end::Title-->

            <!--begin::Description-->
            <div class="text-gray-400 fw-bold fs-5">
              Showing sub equipment categories for
              {{ parentEquipmentCategory.name }}
            </div>
            <!--end::Description-->
          </div>
          <!--end::Heading-->
          <div
              class="card-toolbar d-flex align-items-end justify-content-end mb-3"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-trigger="hover"
              title="Click to add a sub category"
          >
            <a
                href="#"
                class="btn btn-sm btn-success"
                data-bs-toggle="modal"
                data-bs-target="#EquipmentCategoryModal"
                data-bs-dismiss="modal"
                @click.prevent="$emit('addNewSubCategory', parentEquipmentCategory)"
            >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="media/icons/duotone/Code/Plus.svg" />
            </span>
              Add new Sub Category
            </a>
          </div>
          <!--begin::Table-->
          <table
            class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
          >
            <thead>
              <tr class="fw-bolder text-muted">
                <th class="min-w-lg-100px">Name</th>
                <th class="min-w-120px text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(childEquipmentCategory,
                index) in parentEquipmentCategory.children"
                :key="index"
              >
                <td>
                  <router-link
                    :to="'/equipment-category/' + childEquipmentCategory.id"
                    class="text-dark fw-bolder text-hover-primary fs-6"
                    >{{ childEquipmentCategory.name }}</router-link
                  >
                </td>
                <td class="text-end">
                  <div class="btn-group btn-group-sm pb-2 pt-1" role="group">
                    <button
                      @click="emitEditEvent(childEquipmentCategory)"
                      type="button"
                      class="btn btn-sm btn-warning"
                      data-bs-toggle="modal"
                      data-bs-target="#EquipmentCategoryModal"
                      data-bs-dismiss="modal"
                    >
                      Edit
                    </button>
                    <button
                      @click="openDeleteModal(childEquipmentCategory)"
                      class="btn btn-sm btn-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                      data-bs-dismiss="modal"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!--end::Table-->

          <!--begin::Notice-->
          <!--end::Notice-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal-->
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ChildrenEquipmentCategoriesModal",
  components: {
  },
  props: {
    parentEquipmentCategory: Object
  },
  methods: {
    emitEditEvent(equipmentCategory) {
      this.$emit("editModalOpened", equipmentCategory);
    },
    openDeleteModal(equipmentCategory) {
      this.$emit("deleteModalOpened", equipmentCategory);
    }
  },
});
</script>
