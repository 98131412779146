<template>
  <!--begin:equipmentCategoriesIndex-->
  <div class="row gy-5 g-xl-8">
    <EquipmentCategoriesIndex widget-classes="card-xxl-stretch mb-5 mb-xl-8"></EquipmentCategoriesIndex>
  </div>
  <!--end::equipmentCategoriesIndex-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import EquipmentCategoriesIndex from "@/components/settings/EquipmentcategoriesIndex.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "DocumentTypesView",
  components: {
    EquipmentCategoriesIndex
  },
  mounted() {
    setCurrentPageTitle("Equipment Categories");
  }
});
</script>
