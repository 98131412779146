<template>
  <!--begin::Modal-->
  <div
    class="modal fade"
    ref="EquipmentCategoryModal"
    id="EquipmentCategoryModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Form-->
        <Form
          class="form"
          id="EquipmentCategoryModal_form"
          :validation-schema="validationSchema"
          @submit="submit"
        >
          <!--begin::Modal header-->
          <div class="modal-header" id="EquipmentCategoryModal_header">
            <!--begin::Modal title-->
            <h2>{{ title }}</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div
              class="scroll-y me-n7 pe-7"
              id="EquipmentCategoryModal_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#EquipmentCategoryModal_header"
              data-kt-scroll-wrappers="#EquipmentCategoryModal_scroll"
              data-kt-scroll-offset="300px"
            >
              <div :class="`row ${parentEquipmentCategory ? '' : 'mb-5'}`">
                <div class="col-md-12 fv-row">
                  <!--begin::Label-->
                  <label class="required fs-5 fw-bold mb-2">{{
                    parentEquipmentCategory
                      ? "Sub Category Name"
                      : "Equipment Category Name"
                  }}</label>
                  <!--end::Label-->

                  <!--begin::Input-->
                  <Field
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Enter name"
                    name="name"
                    v-model="equipmentCategoryData.name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
              </div>
              <div class="row" v-if="!parentEquipmentCategory">
                <div class="mb-3 mt-3 col-12">
                  <label for="equipmentCategoriesDropdown" class="form-label"
                    >Parent Equipment Category</label
                  >
                  <el-select
                    v-model="equipmentCategoryData.parent_id"
                    filterable
                    clearable
                    size="large"
                    placeholder="Equipment Category"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in action == 'edit'
                        ? equipmentCategories.filter(
                            i => equipmentCategoryData.id != i.id
                          )
                        : equipmentCategories"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              type="reset"
              id="EquipmentCategoryModal_cancel"
              class="btn btn-white me-3"
              data-bs-dismiss="modal"
            >
              Discard
            </button>
            <!--end::Button-->

            <!--begin::Button-->
            <button
              type="submit"
              id="EquipmentCategoryModal_submit"
              class="btn btn-primary"
            >
              <span class="indicator-label"> Submit </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Modal footer-->
        </Form>
        <!--end::Form-->
      </div>
    </div>
  </div>
  <!--end::Modal - New Address-->
</template>

<script lang="js">
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { Modal } from 'bootstrap';

function initialEquipmentCategoryData() {
  return {
        id: "",
        name: "",
    // eslint-disable-next-line @typescript-eslint/camelcase
        parent_id: ""
}};

export default defineComponent({
  name: "EquipmentCategoryModal",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  props: {
    currentlySelectedEquipmentCategory: Object,
    parentEquipmentCategory: Object,
    equipmentCategories: Object,
    action : String,
    title : String,
  },
  mounted() {
    this.modal = new Modal(this.$refs.EquipmentCategoryModal)
  },
  data() {
    return {
    values: {
      equipmentCategories: ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required()
        .label("Name")
    }),
      equipmentCategoryData: initialEquipmentCategoryData(),
      modal: null
    };
  },
  methods: {
    submit() {
      this.validationSchema.isValid(this.equipmentCategoryData).then((validationResult) => {
        if(validationResult){
          this.modal.hide();
          // eslint-disable-next-line @typescript-eslint/camelcase
          if (this.parentEquipmentCategory) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            this.$emit("saveChanges", {payload : {...this.equipmentCategoryData, parent_id: this.parentEquipmentCategory.id}, action : this.action});
          } else {
            this.$emit("saveChanges", {payload : this.equipmentCategoryData, action : this.action});
          }
        }
      })
    },
  },
  watch: {
    currentlySelectedEquipmentCategory() {
      if(this.action == "edit" ){
      this.equipmentCategoryData.id = this.currentlySelectedEquipmentCategory.id;
      this.equipmentCategoryData.name = this.currentlySelectedEquipmentCategory.name;
        // eslint-disable-next-line @typescript-eslint/camelcase
      this.equipmentCategoryData.parent_id = this.currentlySelectedEquipmentCategory.parent_id;
      }
    },
    title() {
      if(this.action !== "edit") this.equipmentCategoryData = initialEquipmentCategoryData();
    }
 }
});
</script>
